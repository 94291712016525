import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';
import MaxWidth from '../../Layout/MaxWidth';

const StyledMax = styled(MaxWidth)`
  margin-top: 2%;
  margin-bottom: 2%;
  ${theme.below.md} {
    padding: 0;
  }
`;

const Wrapper = styled.div``;

const TextBoxWrapper = styled('div')`
  margin: 0 auto 0;
  width: 100%;
  .content-html-text {
    width: 100%;
    margin: 0 auto;
    ${contentTextElements} {
      padding-left: ${theme.general.pagePadding.sm};
      padding-right: ${theme.general.pagePadding.sm};
    }
    &.center {
      > ul {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 15px;
          display: flex;
          align-items: center;
          flex-direction: column;
          img {
            margin-bottom: 5px;
            max-width: 40px;
          }
        }
      }
    }
  }
  padding: 1.5rem 0;
  &.primary {
    background: ${theme.startPage.row.background.primary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.primary};
    }
  }
  &.secondary {
    background: ${theme.startPage.row.background.secondary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.secondary};
    }
  }
`;

const TextBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text?.value;
  const content = props.content?.value;
  const textAlign = props.textAlign.value;
  const textColor = props.textColor.value;
  const background = props.background.value;
  const maxWidth = props.maxWidth && props.maxWidth.value;
  const margins = props.margins?.value === 'yes';
  const Tag = margins ? StyledMax : Wrapper;
  return (
    <Tag>
      <TextBoxWrapper className={`${background}`} data-index={index}>
        <ContentHtml
          style={{ maxWidth: maxWidth }}
          className={`content-html-text ${textColor} ${textAlign}`}
          content={text}
        />
        <ContentHtml
          style={{ maxWidth: maxWidth }}
          className={`content-html-text ${textColor} ${textAlign}`}
          content={content}
        />
      </TextBoxWrapper>
    </Tag>
  );
};

export default TextBox;
