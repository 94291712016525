import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

const InfoIcon = ({ className = '', ...rest }) => {
  return (
    <FontAwesomeIcon icon={faInfoCircle} {...rest} className={className} />
  );
};

export default InfoIcon;
