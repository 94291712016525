export function getId(product) {
  return (
    product &&
    product.articleNumber
      ?.trim()
      .substr(0, product.articleNumber.trim().lastIndexOf('-'))
  );
}
export function getList(data, pos = 0) {
  return data.map((item) => {
    const data = item.split(',');
    if (data.length > pos) return data[pos];
    else return null;
  });
}
export function getColor(product) {
  return product?.customFields
    ?.filter((item) => item.key === 'colour')[0]
    ?.stringValue.toLowerCase();
}
export function filterList(products, term) {
  let list = products.filter((product) => {
    if (getId(product) === term) return product;
    else return null;
  });
  return list.sort((a, b) => (a.articleNumber > b.articleNumber ? 1 : -1));
}

export function filterOutMainProduct(products, mainProduct) {
  return products.filter((product) => {
    return product.id !== mainProduct.id;
  });
}
