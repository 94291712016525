import React, { Fragment, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { Below } from '@jetshop/ui/Breakpoints';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import DataProvider from './Categories/CategoryProvider';
import TopBar from './TopBar/TopBar';
import DesktopHeader from './Desktop/DesktopHeader';
import DesktopNav from './Desktop/DesktopNav';
import MobileHeader from './Mobile/MobileHeader';
import MobileNav from './Mobile/MobileNav';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theming/Theming';
import { useLocation } from 'react-router';

const HeaderWrapper = styled('header')`
  position: sticky;
  top: 0;
  z-index: 104;
  width: 100%;
  a {
    text-decoration: none;
  }
  &[data-startpage='true'] {
    position: fixed;
  }
`;

const Header = ({ categoryTreeRoots, categories }) => {
  const [searchOpen, setSearch] = useState(false);
  const [scrolling, setScroll] = useState(false);
  const location = useLocation();
  const isStart = location.pathname === '/';
  const [activeCategory, setActiveCategory] = useState(null);
  const [subMenuOpen, setSubMenu] = useState(false);

  const handleScroll = (event) => {
    if (typeof window === 'undefined') return false;

    if (window.scrollY > theme.header.scrollBreak && !scrolling) {
      setScroll(true);
    } else if (window.scrollY === 0 && scrolling) {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <>
      <Below breakpoint="lg">
        {(matches) =>
          matches ? (
            <Fragment>
              <HeaderWrapper id="main-header">
                <TopBar scrolling={scrolling} isStart={isStart} />
                <MobileHeader
                  searchOpen={searchOpen}
                  setSearch={setSearch}
                  scrolling={scrolling}
                  isStart={isStart}
                />{' '}
              </HeaderWrapper>
              <DrawerTarget id="menuDrawer" showCover={true}>
                {({ isOpen, hideTarget }) => (
                  <MobileNav
                    categories={categories}
                    categoryTreeRoots={categoryTreeRoots}
                    onClick={hideTarget}
                    isOpen={isOpen}
                    hideTarget={hideTarget}
                  />
                )}
              </DrawerTarget>
            </Fragment>
          ) : (
            <HeaderWrapper id="main-header" data-startpage={isStart}>
              <TopBar scrolling={scrolling} isStart={isStart} />
              <DesktopHeader
                scrolling={scrolling}
                searchOpen={searchOpen}
                setSearch={setSearch}
                isStart={isStart}
                subMenuOpen={subMenuOpen}
                setActiveCategory={setActiveCategory}
                setSubMenu={setSubMenu}
              >
                <DesktopNav
                  scrolling={scrolling}
                  categoryTreeRoots={categoryTreeRoots}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                  subMenuOpen={subMenuOpen}
                  setSubMenu={setSubMenu}
                />
              </DesktopHeader>
            </HeaderWrapper>
          )
        }
      </Below>
      <CartFlyout />
    </>
  );
};

const HeaderComponent = () => (
  <DataProvider>{(props) => <Header {...props} />}</DataProvider>
);

export default HeaderComponent;
