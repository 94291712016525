import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchField } from '../Search';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePadding } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import Logo from '../Logo';
import { FavouriteCount } from '../../../ProductList/FavouriteCount';
import { theme } from '../../../Theming/Theming';
import { IconTooltip } from '../../../ui/icons/IconTooltip';
import StoreIcon from '../../../ui/icons/StoreIcon';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

export const headerTransitions = `
  transition: all 0.5s ease;
`;

const DesktopHeaderWrapper = styled('div')`
  &[data-startpage='false'],
  &[data-scrolling='true'],
  &[data-submenu-open='true'] {
    background: ${theme.desktopHeader.background};
  }
  height: ${theme.desktopHeader.height.lg + 'px'};
  ${headerTransitions}
  padding: 0 0;
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.height.scroll + 'px'};
    box-shadow: 0px 1px 5px #0000001c;
  }
  .page-padding,
  .inner {
    height: 100%;
  }
  .inner {
    display: flex;
    align-items: center;
  }

  .top-nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    > li {
      padding: 0 0.6rem;
      display: flex;
      height: 100%;
      align-items: center;
      &:last-child {
        padding-right: 0.6rem !important;
      }
    }
  }
  .header-icon {
    font-size: 22px;
    color: ${theme.desktopHeader.color};
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
  }
  &[data-startpage='true']&[data-scrolling='false']&[data-submenu-open='false'] {
    .header-icon,
    .slider-items > ul > li > a > h3 {
      color: white;
    }
    .search-field {
      input {
        background: none;
        color: white;
      }
    }
    .submit-search {
      svg {
        color: white;
      }
    }
  }
  .header-button {
    position: relative;
    display: flex;
  }
`;

const StoreLogo = styled('div')`
  transition: height 0.4s ease;
  width: 140px;
  transition: width 0.4s ease;
  //margin: 0 auto;
  ${theme.only.md} {
    max-height: ${theme.desktopHeader.height.md - 20 + 'px'};
  }
  text-align: center;
  text-decoration: none;
  color: inherit;
  a {
    text-decoration: none;
    color: inherit;
    > img,
    > div,
    > svg {
      transition: max-height 0.4s ease;
      max-width: 100%;
      max-height: ${theme.desktopHeader.height.lg - 20 + 'px'};
      ${theme.only.md} {
        max-height: ${theme.desktopHeader.height.md - 20 + 'px'};
      }
    }
  }
  &[data-scrolling='true'] {
    max-height: ${theme.desktopHeader.height.scroll + 'px'};
    a {
      > img,
      > div,
      > svg,
      > .scroll-logo {
        max-height: ${theme.desktopHeader.height.scroll - 20 + 'px'};
      }
    }
    .scroll-logo {
    }
  }
`;

export const HeaderButton = styled('button')`
  display: flex;
`;

export const HeaderIconText = styled('span')`
  display: none;
  ${theme.below.lg} {
    display: none;
  }
`;

const SearchFieldWrapper = styled('div')`
  position: relative;
  .search-field {
    padding-right: 2rem;
  }
  .search-wrapper {
    width: 180px;
    padding-right: 0;
    z-index: 1;
  }
  input {
    border-bottom: 1px solid #ddd;
    font-size: 0.8rem;
    padding-left: 0;
    height: 26px;
  }
  .submit-search {
    right: 0;
  }
  .close-search {
    display: none;
  }
`;

const DesktopHeader = ({
  scrolling,
  searchOpen,
  setSearch,
  children,
  isStart,
  subMenuOpen,
  setSubMenu,
}) => {
  const { storeLink, storeText } = useContext(SiteContentContext);
  return (
    <DesktopHeaderWrapper
      data-scrolling={scrolling}
      data-startpage={isStart}
      data-submenu-open={subMenuOpen || searchOpen}
    >
      <PagePadding>
        <Inner className="inner">
          <StoreLogo data-scrolling={scrolling}>
            <Link to="/" onClick={() => setSubMenu(false)}>
              <Logo type="desktop" />
            </Link>
          </StoreLogo>
          {children}
          <TopNav right>
            <SearchFieldWrapper data-scrolling={scrolling}>
              <SearchField
                onCancel={() => {
                  setSearch(false);
                }}
                setSearch={setSearch}
                searchOpen={searchOpen}
                scrolling={scrolling}
                focusOnLoad={false}
                onFocus={() => {
                  setSearch(true);
                }}
              />
            </SearchFieldWrapper>
            <FavouriteCount className="header-button" />
            {storeLink && (
              <IconTooltip text={storeText}>
                <Link to={storeLink} className="header-button">
                  <StoreIcon className="header-icon" />
                </Link>
              </IconTooltip>
            )}
            <CartButton className="header-button" />
          </TopNav>
        </Inner>
      </PagePadding>
    </DesktopHeaderWrapper>
  );
};

export default DesktopHeader;
