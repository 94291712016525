import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import t from '@jetshop/intl';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Tooltip = styled.h5`
  position: absolute;
  top: 34px;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 5px 6px;
  font-size: 0.8rem;
  ${theme.below.md} {
    display: none;
  }
`;

export const IconTooltip = ({ className = '', text = '', children }) => {
  const [toolTip, setToolTip] = useState(false);
  return (
    <>
      <Wrapper
        className={className}
        onMouseEnter={() => {
          setToolTip(true);
        }}
        onMouseLeave={() => {
          setToolTip(false);
        }}
      >
        {children}
        {toolTip && <Tooltip>{t(text)}</Tooltip>}
      </Wrapper>
    </>
  );
};
