import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import productRowQuery from './ProductRowQuery.gql';
import ProductRowExtraQuery from './ProductRowExtraQuery.gql';
import { useInView } from 'react-intersection-observer';
import { RowProducts } from './RowProducts';

const ProductRowOuterWrapper = styled('div')`
  width: 100%;
  [data-visible='false'] > div {
    padding-top: 500px;
  }
`;

const ProductRow = ({
  id,
  limit = 8,
  orderBy = null,
  orderByDirection = 'DESCENDING',
  excludeId = false,
  slider = false,
  title = '',
  subTitle = '',
  ssr = false,
  quickBuy = false,
  ...rest
}) => {
  const [showed, setShowed] = useState(false);

  const [ref, inView] = useInView();

  const result = useQuery(quickBuy ? ProductRowExtraQuery : productRowQuery, {
    variables: {
      id: parseInt(id, 10),
      first: parseInt(limit, 10),
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      /*filters: {
        booleanFilters: [
          {
            id: id + ':buyable',
            value: true
          }
        ]
      }*/
    },
    skip: !showed,
  });

  const categoryData = result?.data?.category;

  const hasProducts = categoryData?.products?.result?.length > 0;

  const products = hasProducts && {
    ...categoryData.products,
    result: excludeId
      ? categoryData.products.result.filter((prod) => prod.id !== excludeId)
      : categoryData.products.result,
  };

  const category = { ...categoryData, products: products };

  useEffect(() => {
    if (inView && !showed) setShowed(true);
  }, [inView, showed]);

  return (
    <ProductRowOuterWrapper
      data-visible={showed}
      className="product-row-outer-wrapper"
    >
      <div ref={ref}>
        {hasProducts && (
          <RowProducts
            category={category}
            slider={slider}
            title={title}
            subTitle={subTitle}
            quickBuy={quickBuy}
            {...rest}
          />
        )}
      </div>
    </ProductRowOuterWrapper>
  );
};

export default ProductRow;
