/* eslint-disable */
import { theme } from './Theming';
import { css } from 'linaria';
import '@jetshop/ui/Theme/sanitizeCss';
import { getFontSize } from './helpers';

css`
  :global() {
    ${styles};
  }
`;

const styles = `
    html, body {
      height: 100%;
    }
  
    html, body, #root {
      min-height: 100%;
    }
    /* 
    * apply a natural box layout model to all elements, but allowing components
    * to change */
    html {
      box-sizing: border-box;
    }
    *, *:before, *:after {
      box-sizing: inherit;
    }
    html, body, #root {
      min-height: 100%;
    }
    #root {
      display: flex;
      flex-direction: column;
    }
    .ReactModal__Overlay--after-open {
      z-index: 999;
      background-color: rgba(105, 105, 105, 0.75) !important;
    }
    html{
      font-size: ${theme.typography.size}; 
    }
    body{
      font-family: ${theme.typography.fontFamily}, arial, sans-serif;
      color: ${theme.colors.font};
      -webkit-font-smoothing: antialiased;
      font-weight:${theme.typography.fontWeight};
      background: ${theme.colors.white};
      line-height: 1.5;
      letter-spacing: ${theme.typography.letterSpacing};
      visibility: visible;
      margin: 0;
    }

    h1 { ${getFontSize(theme.typography.header.fontSize[1])} }
    h2 { ${getFontSize(theme.typography.header.fontSize[2])} }
    h3 { ${getFontSize(theme.typography.header.fontSize[3])} }
    h4 { ${getFontSize(theme.typography.header.fontSize[4])} }
    h5 { ${getFontSize(theme.typography.header.fontSize[5])} }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      line-height: 1.5;
      font-family: ${theme.typography.header.fontFamily}, arial, sans-serif;
      font-weight: ${theme.typography.header.fontWeight};
      text-transform: ${theme.typography.header.textTransform};
      letter-spacing: ${theme.typography.header.letterSpacing};
      &.body-font{
        font-family: ${theme.typography.fontFamily};
        font-weight: ${theme.typography.fontWeight};
      }
      &.second-color, &.secondary-color, &.secondary{
        color:  ${theme.typography.header.secondColor.color} !important;
      }
      &.second-font, &.secondary-font{
        font-family:  ${
          theme.typography.header.secondFont.fontFamily
        } !important;
        font-weight:  ${
          theme.typography.header.secondFont.fontWeight
        } !important;
        text-transform:  ${
          theme.typography.header.secondFont.textTransform
        } !important;
        letter-spacing: ${theme.typography.header.secondFont.letterSpacing};
      }
      &.thin{
        font-weight: ${theme.weights.light} !important;
      }
      &.ultra{
        font-weight: ${theme.weights.ultra} !important;
      }
      &.underline{
        text-decoration: underline;
      }
    }
    
    h1, h2, h3, h4, h5, h6, p{
      &.normal{
        font-weight: ${theme.weights.normal} !important;
      }
      &.bold{
        font-weight: ${theme.weights.bold} !important;
      }
      &.thin{
        font-weight: ${theme.weights.light} !important;
      }
      &.ultra{
        font-weight: ${theme.weights.ultra} !important;
      }
    }



    a, a:active{
      color: ${theme.colors.font};
      text-decoration: none;
    }
    a:hover{

    }

    .seo-box a{
      color: ${theme.colors.link};
    }

    p { 
      font-size: 1rem; 
      line-height: 1.6;
      &.large{
        ${theme.above.md}{
          font-size: 1.4rem; 
        }
      }
    }

    .svg-inline--fa {
      height: 1em;
    }
    
    strong{
      font-weight: ${theme.weights.bold};
    }

    img, iframe{
      max-width: 100%;
    }

    [data-flight-image-loaded="false"]{
      >div{
        font-size: 0;
      }
    }

    button, a, input {
      :focus {
        outline: none;
      }
    }

    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
      background: transparent;
      font-family: ${theme.typography.fontFamily};
      font-size:${theme.typography.size};
      letter-spacing: ${theme.typography.letterSpacing};
      color: ${theme.colors.font};
    }

    input, text-area, select {
      font-family: ${theme.typography.fontFamily};
      font-size:${theme.typography.size};
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ol{
      margin-left: 1em;
    }

    p, dd {
      margin: 0;
      padding: 0;
    }

    select{
      font-size: 1rem;
    }
    
    .content-area{
      h1, h2, h3, h4, h5, h6{
        margin: 2rem 0 1rem 0;
      }
      p{
        margin: 0 0 1em 0;
      }
      >*:first-child{
        margin-top: 0 !important;
      }
      &.small{
        h1, h2, h3, h4, h5, h6{
          margin: 0.5em 0 1em 0;
        }
      }
      a{
        text-decoration: underline;
      }
      ul{
        list-style-type: disc;
        padding-inline-start: 1rem;
      }
    }

  `;
