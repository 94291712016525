import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import Image from '@jetshop/ui/Image';
import NewsletterBox from './Newsletter/NewsletterBox';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import Logo from '../Header/Logo';

const FooterWrapper = styled('footer')`
  margin-top: 30px;
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:focus {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0 0;
    color: ${theme.footer.color};
  }
  .usp-box {
    background: ${theme.colors.lightgrey};
    padding: 2rem 0;
    > div {
      max-width: 1200px;
      margin: 0 auto;
    }
    ul {
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      flex-wrap: wrap;
      li {
        padding: 2rem;
        img {
          max-width: 40px;
          margin-bottom: 5px;
        }
      }
    }
  }
`;

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  padding-top: 0.5rem;
  margin-bottom: 3rem;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 40%;
      text-align: center;
    }
    ${theme.above.lg} {
      width: 20%;
      padding: 3rem 1rem;
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    img {
      max-width: 25px;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    a {
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

const FooterBottomWrapper = styled.div`
  border-top: 1px solid ${theme.colors.white};
  padding: 2rem 0;
  > div {
    > div {
      width: 100%;
    }
  }
  ${theme.above.md} {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      .logo {
        width: 20%;
      }
      .footer-content {
        width: 50%;
      }
      .brand-logo {
        width: 20%;
      }
    }
  }
  .footer-content {
    padding: 0;
    ${theme.below.md} {
      padding: 2rem 0;
      text-align: center;
    }
  }
  .logo {
    max-width: 200px;
    ${theme.below.md} {
      margin: 0 auto;
    }
  }
`;

export const FooterLogo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  background: ${theme.footer.logoWrapper.background};
  border-top: 1px solid white;
  > div {
    width: 100%;
    max-width: 500px;
  }
`;

const Footer = () => {
  const {
    uspBox,
    footer1,
    footer2,
    footer3,
    footer4,
    footer5,
    footerBottom,
    footerLogo,
    brandLogo,
  } = useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="usp-box">
          <div>{uspBox}</div>
        </div>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContentWrapper>
              <div>{footer1}</div>
              <div>{footer2}</div>
              <div>{footer3}</div>
              <div>{footer4}</div>
              <div>{footer5}</div>
            </FooterContentWrapper>
          </MaxWidth>
        </div>
        <div className="footer-bottom">
          <FooterBottomWrapper>
            <MaxWidth>
              <div className="logo">
                <Logo />
              </div>
              <div className="footer-content">{footerBottom}</div>
              <div className="brand-logo">
                <Image aspect="3:1" src={brandLogo} />
              </div>
            </MaxWidth>
          </FooterBottomWrapper>
        </div>
      </FooterWrapper>
      {footerLogo && (
        <FooterLogo>
          <div>
            <Image aspect="5:1" src={footerLogo} />
          </div>
        </FooterLogo>
      )}
    </>
  );
};

export default Footer;
