import React, { useEffect } from 'react';

const host = 'https://www.alpingaraget.se';

const Widget = ({ type, product }) => {
  const common = {
    id: 'lipscore-' + type,
    className: 'lipscore-widget',
    productName: product.name,
    brand: product.customFields?.filter((item) => item.key === 'varumarke')[0]
      ?.stringValue,
    productId: product.id,
    productUrl: product.primaryRoute.path,
    description: product.description,
    imageUrl: product.images && product.images.length && product.images[0].url,
    price: product.price.price,
    stockStatus: product.stockStatus?.text,
  };
  switch (type) {
    case 'rating':
      return (
        <div
          id="lipscore-rating"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-price-currency="SEK"
          data-ls-availability={common.stockStatus}
        />
      );
    case 'rating-small':
      return (
        <span
          className="lipscore-rating-small"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-price-currency="SEK"
          data-ls-availability={common.stockStatus}
        ></span>
      );
    case 'review-list':
      return (
        <div
          id="lipscore-review-list"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-price-currency="SEK"
          data-ls-availability={common.stockStatus}
        ></div>
      );
    case 'review-count':
      return (
        <span
          className="lipscore-review-count"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-price-currency="SEK"
          data-ls-availability={common.stockStatus}
        ></span>
      );
    default:
      return (
        <div
          id="lipscore-rating"
          data-ls-product-name={common.productName}
          data-ls-brand-name={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
        />
      );
  }
};

const LipscoreWidget = ({ type, product = null }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.lipscore)
      window.lipscore.initWidgets();
  });

  return <Widget type={type} product={product} />;
};

export default LipscoreWidget;
