import React from 'react';
import fetch from 'node-fetch';
import t from '@jetshop/intl';

import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { useEffect, useReducer } from 'react';
import { theme } from '../Theme';

const authId = 'Basic bGVuYUBlYmVsaW5nd2ViYi5zZTpFYmVsaW5nMTI3';

const OuterWrapper = styled.div`
  h2 {
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border-bottom: 0.15em solid currentColor;
  }
  & > div {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
`;

const StreamListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  ${theme.below.lg} {
    display: block;
  }
`;

const StreamWrapper = styled.div``;

const Stream = ({ streamId }) => {
  return (
    <StreamWrapper>
      <streamify-liveshopping
        id={streamId}
        button-text="A Live Shopping event"
      ></streamify-liveshopping>
    </StreamWrapper>
  );
};

const StreamList = ({ type = 'upcoming', title = null }) => {
  const [streams, updateStreams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { streams: [], loaded: false }
  );

  useEffect(() => {
    fetch(`https://api.streamify.io/live/broadcast?type=${type}`, {
      headers: {
        Authorization: authId,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        updateStreams({ streams: res, loaded: true });
      });
  }, []);

  return (
    <div>
      {title && <h2>{t(title)}</h2>}
      <StreamListWrapper>
        {streams?.loaded && streams.streams.length === 0 && (
          <p>{t('No streams to show.')}</p>
        )}
        {!streams?.loaded && <p>{t('Loading...')}</p>}
        {streams?.streams.map((stream) => (
          <Stream streamId={stream.id} />
        ))}
      </StreamListWrapper>
    </div>
  );
};

function LiveShopping() {
  const [streams, updateStreams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { streams: [], loaded: false }
  );

  console.log(streams);

  useEffect(() => {
    fetch('https://api.streamify.io/live/broadcast?type=past', {
      headers: {
        Authorization: authId,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        updateStreams({ streams: res, loaded: true });
      });
  }, []);
  return (
    <MaxWidth>
      <OuterWrapper>
        <StreamList type="upcoming" title="Upcoming streams" />
        <StreamList type="past" title="Past streams" />
      </OuterWrapper>
    </MaxWidth>
  );
}

export default LiveShopping;
