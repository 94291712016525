import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { ContentHtml } from '../ContentText';
import { ContentLink } from '../ContentLink';

export const RowHeaderWrapper = styled.header`
  text-align: center;
  margin: 3rem 0 2rem;
  ${theme.above.lg} {
    margin: 4rem 0 3rem;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &[data-max-width='false'] {
    padding: 0 1rem;
  }
`;

export const RowHeader = ({
  props,
  children,
  className = '',
  maxWidth = false,
}) => {
  const content = props.header.value;
  const link = props.headerLink.value;

  if (!content) return null;

  return (
    <RowHeaderWrapper
      className={`row-header content-html-text ${className}`}
      data-max-width={maxWidth}
    >
      {link ? (
        <ContentLink to={link}>
          <ContentHtml content={content} />
        </ContentLink>
      ) : (
        <ContentHtml content={content} />
      )}
      {children}
    </RowHeaderWrapper>
  );
};
