import React, {
  useEffect,
  useRef,
  useContext,
  Fragment,
  useState,
} from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import t from '@jetshop/intl';

const TagsMenu = styled('div')`
  display: flex;
  margin: 1rem 0 0 0;
  justify-content: center;
  flex-wrap: wrap;
`;

const TagButton = styled('button')`
  padding: 0.5rem 1rem;
  border: 1px solid ${theme.colors.borderDark};
  text-transform: uppercase;
  margin-bottom: 1rem;
  &:not(:last-child) {
    margin-right: 1rem;
  }
  &.selected {
    background: #333;
    color: white;
  }
`;

// Supported by Flowbox.
const supportedCultures = [
  'de-DE',
  'da-DK',
  'en-GB',
  'es-ES',
  'fi-FI',
  'fr-FR',
  'nl-NL',
  'nb-NO',
  'pt-PT',
  'sv-SE',
];

const initializeFlow = ({
  method = 'init',
  feedKey,
  elementId,
  productId,
  culture,
  tags,
}) => {
  const settings = {
    container: `#${elementId}`,
    key: feedKey,
    locale: supportedCultures.includes(culture) ? culture : 'en-GB',
    tags: tags,
  };
  if (productId) {
    window.flowbox(method, {
      ...settings,
      productId: productId ? productId.toString() : undefined,
    });
  } else {
    window.flowbox(method, settings);
  }
};

const initializeFlowbox = (options) => {
  if (!window.flowbox) {
    // Flowbox
    (function (d, id) {
      if (!window.flowbox) {
        var f = function () {
          f.q.push(arguments);
        };
        f.q = [];
        window.flowbox = f;
      }
      if (d.getElementById(id)) {
        return;
      }
      var s = d.createElement('script'),
        fjs = d.scripts[d.scripts.length - 1];
      s.id = id;
      s.async = true;
      s.src = ' https://connect.getflowbox.com/flowbox.js';
      fjs.parentNode.insertBefore(s, fjs);
    })(document, 'flowbox-js-embed');
  }
  return initializeFlow(options);
};

const Flowbox = ({
  feedKey = 'test',
  elementId = 'js-flowbox-feed',
  productId = null,
  tags = [],
  menuTags = [],
}) => {
  const { selectedChannel = {} } = useContext(ChannelContext);
  const { culture } = selectedChannel.language;
  const flowBoxElement = useRef();
  const [selectedIndex, setSelectedIndex] = useState(null);
  useEffect(() => {
    if (flowBoxElement.current) {
      initializeFlowbox({
        feedKey,
        elementId,
        productId,
        culture,
        tags,
      });
    }
  }, [feedKey, productId, culture, elementId, tags]);

  return (
    <Fragment>
      {menuTags.length > 0 && (
        <TagsMenu>
          {menuTags.map((tag, index) => {
            return (
              <TagButton
                key={index}
                className={index === selectedIndex ? 'selected' : ''}
                onClick={() => {
                  const tagUpdate = index === selectedIndex ? [] : [tag];
                  const newIndex = index === selectedIndex ? null : index;
                  initializeFlow({
                    method: 'update',
                    feedKey,
                    elementId,
                    productId,
                    culture,
                    tags: tagUpdate,
                  });
                  setSelectedIndex(newIndex);
                }}
              >
                {t(tag)}
              </TagButton>
            );
          })}
        </TagsMenu>
      )}
      <div
        id={elementId}
        ref={flowBoxElement}
        className={css`
          .bzfy-t-feed-carousel,
          .bzfy-feed {
            background-color: inherit !important;
          }
          .bzfy-feed {
            .bzfy-o-layout {
              margin-left: 0;
            }
          }
          .bzfy-t-feed-carousel
            .bzfy-c-mobile-carousel-control
            .bzfy-c-mobile-circle {
            width: 30px;
            height: 30px;
          }
        `}
      />
    </Fragment>
  );
};

export default Flowbox;
