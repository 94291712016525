import React from 'react';
import { styled } from 'linaria/react';
import { convertPropsToObject } from '../ContentRender';
import Flowbox from '../../ui/Flowbox';

const FlowboxComponentWrapper = styled('div')`
  margin: 2% 0;
`;

const FlowboxComponent = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const rowProps = {
    feedKey: props.feedKey.value,
  };

  if (!rowProps.feedKey) return null;
  return (
    <FlowboxComponentWrapper>
      <Flowbox feedKey={rowProps.feedKey} />
    </FlowboxComponentWrapper>
  );
};

export default FlowboxComponent;
