import useScript from '../ui/hooks/useScript';

export function LipscoreScript() {
  if (typeof window !== 'undefined') {
    window.lipscoreInit = function () {
      window.lipscore.init({
        apiKey: '8af4f161a5b4db4913885168',
      });
    };
  }
  useScript('//static.lipscore.com/assets/se/lipscore-v1.js');

  return null;
}
