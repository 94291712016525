import React from 'react';
import { styled } from 'linaria/react';
import { PagePadding, Inner } from '../../MaxWidth';
import TopBarUsp from './TopBarUsp';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../../Theming/Theming';
import { Notifications } from '../../Notifications';
import TopBarLinks from './TopBarLinks';
import TopNav from '../Util/TopNav';
import { CurrencySelectorDropdown } from '../ChannelSelector/CurrencySelectorDropdown';
import { useState } from 'react';

const TopBarWrapper = styled('div')`
  height: ${theme.topBar.height.lg + 'px'};
  transition: height 0.4s ease;
  ${theme.above.lg} {
    &[data-startpage='false'],
    &[data-scrolling='true'] {
      background: ${theme.topBar.background};
    }
    &[data-startpage='true']&[data-scrolling='false']&[data-dropdown='false'] {
      backdrop-filter: blur(24px);
    }
    &[data-startpage='true']&[data-scrolling='false'] {
      background: rgba(238, 238, 238, 0.1);
      .top-nav-list {
        .channel-selector-button {
          color: white;
        }
      }
      ul {
        li {
          &,
          > a,
          > button,
          > svg {
            color: white;
          }
        }
      }
    }
  }
  ${theme.below.lg} {
    background: ${theme.topBar.background};
  }
  ${theme.below.md} {
    height: ${theme.topBar.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.topBar.height.scroll + 'px'};
    overflow: hidden;
  }
  .top-nav-list {
    height: ${theme.topBar.height.lg + 'px'};
    .channel-selector-button {
      color: ${theme.topBar.color};
    }
  }
`;

const ContentWrapper = styled('div')`
  > div > ul {
    display: flex;
    justify-content: center;
    height: ${theme.topBar.height.lg + 'px'};
    ${theme.below.md} {
      height: ${theme.topBar.height.md + 'px'};
    }
    &:not(.top-nav-list) {
      > li {
        padding: 0 0.666rem;
      }
    }
    > li,
    > li > a,
    > li > button,
    > li:before {
      color: ${theme.topBar.color};
      font-size: ${theme.topBar.fontSize};
      font-weight: ${theme.topBar.fontWeight};
    }
    > li {
      display: flex;
      align-items: center;
      svg {
        color: ${theme.topBar.color};
      }
    }
  }
`;

const TopBar = ({ scrolling, isStart }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <TopBarWrapper
      data-scrolling={scrolling}
      data-startpage={isStart}
      data-dropdown={dropdown}
    >
      <PagePadding>
        <Inner>
          <Above breakpoint="lg">
            {(matches) => (
              <ContentWrapper>
                {matches && (
                  <TopNav left>
                    <CurrencySelectorDropdown
                      dropdown={dropdown}
                      setDropdown={setDropdown}
                    />
                  </TopNav>
                )}
                <TopBarUsp name={'TopBarUsp'} />
                {matches && <TopBarLinks />}
              </ContentWrapper>
            )}
          </Above>
        </Inner>
      </PagePadding>
      <Notifications />
    </TopBarWrapper>
  );
};

export default TopBar;
