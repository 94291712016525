import React, { memo, useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import t from '@jetshop/intl';
import { theme } from '../../../Theming/Theming';
import { useLocation } from 'react-router';
import deepCategoriesQuery from '../Categories/DeepCategoriesQuery.gql';
import { useQuery } from 'react-apollo';
import { cleanCategories } from '../Categories/CategoryProvider';
import Image from '@jetshop/ui/Image';

const CategoryWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  &:first-child {
    border-top: 1px solid ${theme.colors.borderLight};
  }
`;

const CategoryButton = styled('button')`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 0 0 0 ${theme.mobileHeader.nav.padding};
  background: ${theme.colors.white};
  ${StyledCarrot} {
    margin-right: 0px;
  }
  > a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    .image-holder {
      margin-right: 10px;
      display: block;
      width: 20px;
    }
  }
  .icon-holder {
    padding: 0 ${theme.mobileHeader.nav.padding};
    &.open,
    &:hover {
      background: ${theme.colors.primary};
      svg {
        color: ${theme.colors.white};
      }
    }
  }
  border-bottom: 1px solid ${theme.colors.borderLight};
  .icon-holder {
    border-left: 1px solid ${theme.colors.borderLight};
  }
  &[data-level='1'] {
    font-family: ${theme.mobileHeader.nav.lv1.fontFamily};
    font-weight: ${theme.mobileHeader.nav.lv1.fontWeight};
    font-size: ${theme.mobileHeader.nav.lv1.fontSize};
    line-height: ${theme.mobileHeader.nav.lv1.lineHeight};
    text-transform: ${theme.mobileHeader.nav.lv1.textTransform};
  }
  &[data-level='2'] {
    font-size: ${theme.mobileHeader.nav.lv2.fontSize};
    line-height: ${theme.mobileHeader.nav.lv2.lineHeight};
    &,
    .icon-holder {
      border-color: white;
    }
    background: ${theme.colors.lightgrey};
    padding-left: 3rem;
  }
  &[data-level='3'] {
    font-size: ${theme.mobileHeader.nav.lv3.fontSize};
    line-height: ${theme.mobileHeader.nav.lv3.lineHeight};
    padding-left: 4rem;
  }
  &[data-level='4'] {
    font-size: ${theme.mobileHeader.nav.lv4.fontSize};
    line-height: ${theme.mobileHeader.nav.lv4.lineHeight};
    padding-left: 5rem;
  }
  &.active,
  &.active > a {
    //color: ${theme.mobileHeader.nav.activeColor};
  }
  &.selected,
  &.selected > a {
    color: ${theme.mobileHeader.nav.selectedColor};
  }
  &.show-all {
    text-decoration: underline;
    span:not(.show-all-text) {
      text-transform: lowercase;
    }
  }
`;

function inPath(category, pathname) {
  let currentPath = pathname;
  let returnObject = {
    inPath: false,
    exactPath: false,
  };

  if (!pathname) return returnObject;

  if (
    category.primaryRoute &&
    currentPath.search(category.primaryRoute.path) !== -1
  ) {
    returnObject.inPath = true;
    returnObject.exactPath = currentPath === category.primaryRoute.path;
  }
  return returnObject;
}

function removeLast(path) {
  const pathData = path.split('/');
  pathData.pop();
  const returnPath = pathData.join('/');
  return returnPath ? returnPath : 'trail';
}

const Category = memo(
  ({
    children,
    category,
    isOpen = false,
    level,
    hideTarget,
    activeCategory,
    setActiveCategory,
    mobileMenu,
    openCategory,
    setOpenCategory,
  }) => {
    const hasSubs = category.subcategories && category.subcategories.length > 0;
    const location = useLocation();
    let classNames = [];
    const pathObject = inPath(category, location.pathname);
    const pathObjectCat = inPath(category, openCategory || location.pathname);

    if (pathObjectCat.inPath || pathObjectCat.exactPath) {
      isOpen = true;
      classNames.push('active');
    }

    if (pathObject.inPath) {
      classNames.push('active');
    }

    if (pathObject.exactPath || activeCategory === category.id) {
      classNames.push('selected');
    }

    const isDev =
      typeof window !== 'undefined' &&
      (window.location.host.search('dev.jetshop.se') !== -1 ||
        window.location.host.search('localhost') !== -1);

    return (
      <CategoryWrapper>
        <CategoryButton data-level={level} className={classNames.join(' ')}>
          <CategoryLink
            category={category}
            onClick={() => {
              hideTarget();
              setActiveCategory(category.id);
            }}
          >
            {isDev && mobileMenu && level < 3 && (
              <span className="image-holder">
                <Image
                  src={`/pub_images/original/cat-${category.id}.png`}
                  sizes={[30]}
                  aspect={'1:1'}
                />
              </span>
            )}
            {category.name}
          </CategoryLink>
          {hasSubs && (
            <div
              onClick={(e) => {
                e.preventDefault();
                setOpenCategory(
                  isOpen
                    ? removeLast(category.primaryRoute.path)
                    : category.primaryRoute.path
                );
                //setOpen(!isOpen);
              }}
              className={`${isOpen ? 'open' : ''} icon-holder`}
            >
              <StyledCarrot className={isOpen ? 'open' : ''} />
            </div>
          )}
        </CategoryButton>
        {isOpen && hasSubs && (
          <div className="subcategories">
            <CategoryButton data-level={level + 1} className="show-all">
              <CategoryLink
                category={category}
                onClick={() => {
                  hideTarget();
                  setActiveCategory(category.id);
                }}
              >
                <span className="show-all-text">{t('Show all in')}&nbsp;</span>
                <span> {category.name}</span>
              </CategoryLink>
            </CategoryButton>
            {children}
          </div>
        )}
      </CategoryWrapper>
    );
  }
);

export const CategoryTree = ({
  parent = null,
  categories,
  level = 1,
  hideTarget = () => {},
  activeCategory,
  setActiveCategory,
  mobileMenu,
  openCategory,
  setOpenCategory,
}) => {
  if (categories && categories.length > 0) {
    return categories.map((category) => (
      <Category
        category={category}
        key={category.id}
        level={level}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        mobileMenu={mobileMenu}
        openCategory={openCategory}
        setOpenCategory={setOpenCategory}
      >
        <CategoryTree
          parent={category}
          categories={category.subcategories}
          level={level + 1}
          hideTarget={hideTarget}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          mobileMenu={mobileMenu}
          openCategory={openCategory}
          setOpenCategory={setOpenCategory}
        />
      </Category>
    ));
  } else return null;
};

const MobileCategoriesFull = ({
  categories,
  hideTarget,
  activeCategory,
  setActiveCategory,
  mobileMenu,
  openCategory,
  setOpenCategory,
}) => {
  const result = useQuery(deepCategoriesQuery, {
    variables: { levels: 1 },
  });

  const { data } = result;
  const fullCategories =
    (data && cleanCategories(data.categories)) || categories;

  return (
    <CategoryTree
      categories={fullCategories}
      hideTarget={hideTarget}
      activeCategory={activeCategory}
      setActiveCategory={setActiveCategory}
      mobileMenu={mobileMenu}
      openCategory={openCategory}
      setOpenCategory={setOpenCategory}
    />
  );
};

const MobileCategories = ({
  categories,
  hideTarget,
  isOpen,
  mobileMenu = true,
}) => {
  const [activeCategory, setActiveCategory] = useState('trail');
  const [openCategory, setOpenCategory] = useState(false);
  const [open, setOpen] = useState(false);

  if (isOpen && !open) setOpen(true);
  if (open)
    return (
      <MobileCategoriesFull
        {...{
          categories,
          hideTarget,
          activeCategory,
          setActiveCategory,
          mobileMenu,
          openCategory,
          setOpenCategory,
        }}
      />
    );
  if (categories && categories.length > 0) {
    return (
      <CategoryTree
        categories={categories}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        mobileMenu={mobileMenu}
        openCategory={openCategory}
        setOpenCategory={setOpenCategory}
      />
    );
  } else return null;
};

export default MobileCategories;
