import React from 'react';
import { styled } from 'linaria/react';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtmlParse } from '../ContentText';

const Wrapper = styled('div')`
  margin: 2% 0;
`;

const CodeBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text?.value;
  return (
    <Wrapper data-index={index}>
      <div>
        <ContentHtmlParse content={text} />
      </div>
    </Wrapper>
  );
};

export default CodeBox;
