import React from 'react';
import { convertPropsToObject } from '../ContentRender';
import { RowHeader } from './RowHeader';
import MaxWidth from '../../Layout/MaxWidth';
import BlogOnPage from '../../Blog/BlogOnPage';

const BlogComponent = ({ data }) => {
  const props = convertPropsToObject(data);
  const slider = props.slider.value === 'yes' ? true : false;

  return (
    <MaxWidth>
      <RowHeader props={props} maxWidth={true} />
      <BlogOnPage slider={slider} />
    </MaxWidth>
  );
};

export default BlogComponent;
