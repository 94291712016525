import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Caret } from '../../../../svg/Caret.svg';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import CheckIcon from '../../../ui/icons/CheckIcon';
import WalletIcon from '../../../ui/icons/WalletIcon';

export const ChannelSelectorWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  background: transparent;
  position: relative;
  > svg {
    margin-right: 10px;
  }
  .channel-selector-button {
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0 !important;
    justify-content: space-between;
    text-transform: uppercase;
    svg {
      transition: all 200ms;
      margin-left: 5px;
    }
    span {
      font-family: ${theme.button.fontFamily};
      font-weight: ${theme.button.fontWeight};
      //text-transform: ${theme.button.textTransform};
      letter-spacing: ${theme.button.letterSpacing};
    }
  }
  &[data-open='true'] {
    .channel-selector-button {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  .channel-selector-dropdown {
    &[data-dropdown='true'] {
      position: absolute;
      top: 100%;
      width: 140px;
      border: 1px solid ${theme.colors.border};
      z-index: 3;
      .channel-options {
        .channel-option {
          padding: 5px 10px;
          .options {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
    &[data-dropdown='false'] {
      .channel-options {
        .channel-option {
          .options {
           .option{
            margin-right: 10px;
            .icon{
              display: none;
            }
            &.active{
              text-decoration: underline;
              font-weight: ${theme.weights.bold};
            }
           }
          }
        }
    }
  }
  .channel-options {
    .channel-option {
      width: 100%;
      background: ${theme.colors.white};
      .options {
        display: flex;
        .option {
          display: flex;
          align-items: center;
          .icon {
            width: 15px;
            margin-right: 5px;
            border-radius: 50%;

            background: #ffffff;
            border: 1px solid #ddd;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 0.7rem;
              color: ${theme.colors.black};
            }
          }
        }
      }
    }
  }
  .channel-confirm {
    margin-top: 5px;
    button {
      width: 100%;
      height: 40px;
    }
  }
`;

const ChannelOptions = ({ options, selected, clickFunction }) => {
  const sortOptions = [
    ...options.filter((opt) => opt.isDefault),
    ...options.filter((opt) => !opt.isDefault),
  ];
  return (
    <div className="channel-option">
      <div className="options">
        {sortOptions.map((option, index) => (
          <button
            key={index}
            onClick={() => clickFunction(option)}
            className={`option ${selected === option ? 'active' : ''}`}
          >
            <span className="icon">
              {selected === option ? <CheckIcon /> : null}
            </span>
            {option.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export const CurrencySelectorDropdown = ({
  hideDropdown = false,
  dropdown = false,
  setDropdown = () => {},
}) => {
  const { selectedChannel, updateChannel } = useContext(ChannelContext);

  const [activeChannel] = useState(selectedChannel);

  const wrapperRef = React.createRef();

  useEffect(() => {
    //CLOSE OUTSIDE LISTENER
    const listener = (e) => {
      if (!wrapperRef.current) return;
      if (!wrapperRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [setDropdown, wrapperRef]);

  const currencyCode = selectedChannel?.currency?.name;

  return (
    <ChannelSelectorWrapper
      className="channel-selector-wrapper"
      ref={wrapperRef}
      data-open={dropdown}
    >
      {!hideDropdown && (
        <button
          className="channel-selector-button"
          onClick={() => setDropdown(!dropdown)}
        >
          <span className="name">{currencyCode}</span>
          <Caret />
        </button>
      )}
      {hideDropdown && (
        <span className="icon-wrapper">
          <WalletIcon />
        </span>
      )}
      {(dropdown || hideDropdown) && (
        <div
          className="channel-selector-dropdown"
          data-dropdown={!hideDropdown}
        >
          <div className="channel-options">
            <ChannelOptions
              options={activeChannel.currencies}
              selected={activeChannel.currency}
              clickFunction={(option) => {
                updateChannel({
                  ...activeChannel,
                  currency: option,
                });
              }}
              name="Currency"
            />
          </div>
        </div>
      )}
    </ChannelSelectorWrapper>
  );
};
