import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theming/Theming';
import { imageOverlay } from '../ui/General';
import { useLazyQuery } from 'react-apollo';
import ProductGridVariantsQuery from './ProductGridVariantsQuery.gql';
import SiblingProducts from '../ProductPage/SiblingProducts/SiblingProducts';
import LipscoreWidget from '../Lipscore/LipscoreWidget';
import PlusIcon from '../ui/icons/PlusIcon';

export const ProductCardInner = styled('div')`
  padding: ${theme.productGrid.product.padding.sm};
  background-color: ${theme.productGrid.product.background};
  border: ${theme.productGrid.product.border};
  position: relative;
  line-height: 1;
  min-width: 0;
  ${theme.above.md} {
    padding: ${theme.productGrid.product.padding.md};
  }
  ${theme.above.xl} {
    padding: ${theme.productGrid.product.padding.lg};
  }
  > a {
    color: inherit;
    display: block;
  }
  > button {
    width: 100%;
  }
`;

export const Details = styled('div')`
  margin: 0.5rem 0 1rem;
  position: relative;
  height: 64px;
`;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  > * {
    flex-grow: 0;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  ${imageOverlay}
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.9rem;
  img {
    width: auto;
    height: auto;
    max-width: 70px;
    ${theme.below.md} {
      max-width: 50px;
    }
  }
`;

export const Name = styled('h4')`
  margin: 0 0 0.3rem 0 !important;
  padding: 0;
  padding-right: 1.8rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.5;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const SubName = styled('p')`
  margin: 0 0 0.7rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${theme.below.md} {
    font-size: 0.9rem;
  }
`;
export const ListPrice = styled(GridPrice)``;

const PackagePrice = styled('div')`
  font-size: 0.9rem;
  margin-top: 5px;
`;

const ExtraBlock = styled.div`
  transition: all 0.5s;
  height: 0;
  &.open {
    height: 30px;
  }
  display: flex;
  justify-content: space-between;
  .grid-siblings {
    flex-grow: 1;
    .sibling-products {
      width: auto;
    }
  }
  > a {
    flex-grow: 3;
  }
`;

const VariantWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -2px;
  align-items: center;
  .variant {
    padding: 2px 7px;
    background: #eee;
    font-weight: ${theme.weights.bold};
    height: 22px;
    margin: 0 2px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    ${theme.below.sm} {
      font-size: 0.8rem;
    }
    &[data-buyable='false'] {
      background: ${theme.colors.greys[0]};
      color: #bbb;
      text-decoration: line-through;
    }
  }
  > svg {
    margin-left: 2px;
  }
  &.many {
    .variant {
      ${theme.below.md} {
        font-size: 0.8rem;
      }
      ${theme.below.sm} {
        font-size: 0.7rem;
        padding: 2px 5px;
      }
      @media (max-width: 400px) {
        padding: 2px 2px;
      }
    }
  }
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LipScoreWrapper = styled.div`
  svg {
    width: 12px;
    height: 12px;
  }
`;

const VariantDisplay = ({ product }) => {
  const variants = product?.variants?.values;
  const showPlus = variants?.length > 5;
  const many = variants?.length > 4;
  const showVariants = showPlus ? variants?.slice(0, 5) : variants;
  if (!showVariants) return null;
  return (
    <VariantWrapper className={`${many ? 'many' : ''}`}>
      {showVariants.map((variant, index) => (
        <div
          className="variant"
          key={index}
          data-buyable={variant.stockStatus.buyable}
        >
          {variant.values[0]}
        </div>
      ))}
      {showPlus && <PlusIcon />}
    </VariantWrapper>
  );
};

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  loadImageEagerly,
  categoryPath,
  open = false,
  extraInfo,
  ...rest
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  const firstImage = hasImages ? product.images[0] : false;
  const secondImage =
    hasImages && product.images.length > 1
      ? product.images[1]
      : product.images[0];

  const [image, setImage] = useState(firstImage);

  const [imageIndex, setImageIndex] = useState(1);

  useEffect(() => {
    if (secondImage && imageIndex === 2 && image !== secondImage)
      setImage(secondImage);
    else if (firstImage && imageIndex === 1 && image !== firstImage) {
      setImage(firstImage);
    }
  }, [imageIndex, image, firstImage, secondImage]);

  let badges = [...product.badges];

  const { hasVariants } = product;

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge',
    });

  return (
    <ProductCardInner className="product-card-inner">
      <ProductLink product={product} categoryPath={categoryPath} {...rest}>
        {hasImages ? (
          <ImageWrapper
            className="image-wrapper"
            onMouseEnter={() => setImageIndex(2)}
            onMouseLeave={() => setImageIndex(1)}
          >
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={image.alt}
              src={image.url}
              modifiedDate={image.modifiedDate}
              critical={loadImageEagerly}
              crop
              cover
            />
            <BadgeWrapper>
              <Badges badges={product.badges} />
            </BadgeWrapper>
            {showFavorite && (
              <Favourite
                product={product}
                style={{
                  position: 'absolute',
                  top: '3px',
                  right: '3px',
                }}
              />
            )}
          </ImageWrapper>
        ) : (
          <Image src={transparentDataImg} aspect={imageAspect} />
        )}
      </ProductLink>
      <Details
        className={`product-card-detail ${open ? 'open' : ''} ${
          extraInfo ? 'extra' : ''
        }`}
      >
        <ExtraBlock className={open ? 'open' : ''}>
          {open && (
            <>
              <div className="grid-siblings">
                <SiblingProducts product={product} grid={true} />
              </div>
              <ProductLink
                product={product}
                categoryPath={categoryPath}
                {...rest}
              >
                <VariantDisplay product={product} />
              </ProductLink>
            </>
          )}
        </ExtraBlock>
        <DetailsInner>
          <header>
            <ProductLink product={product} categoryPath={categoryPath}>
              <Name>{product.name}</Name>
            </ProductLink>
          </header>
          <PriceRow className="price-row">
            {product.isPackage && (
              <PackagePrice className="package-price-label">
                {t('Package Price')}
              </PackagePrice>
            )}
            <ListPrice
              hidePrice={product.hidePrice}
              price={product.price}
              previousPrice={product.previousPrice}
            />
            {open && (
              <LipScoreWrapper>
                <LipscoreWidget product={product} type="rating-small" />
              </LipScoreWrapper>
            )}
            {!open && hasVariants && <p>{t('Variants +')}</p>}
          </PriceRow>
        </DetailsInner>
      </Details>
      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = theme.productGrid.product.imageAspect,
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  loadImageEagerly = false,
  extraInfo = true,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    loadImageEagerly,
    extraInfo,
    ...linkProps,
  };

  const [open, setOpen] = useState(false);

  const [fetch, { data }] = useLazyQuery(ProductGridVariantsQuery, {
    variables: { articleNumber: product.articleNumber },
  });
  const productInUse = { ...product, ...data?.product };

  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
      onTouchStart={() => {
        if (extraInfo) {
          fetch(true);
          setOpen(true);
        }
      }}
      onMouseEnter={() => {
        if (extraInfo) {
          fetch(true);
          setOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (extraInfo) {
          setOpen(false);
        }
      }}
    >
      <ProductCardComponent product={productInUse} open={open} {...props} />
    </Tag>
  );
}
