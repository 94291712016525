import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import NewsletterField from './NewsletterField';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth, { Inner } from '../../MaxWidth';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem ${theme.general.pagePadding.sm};
    ${theme.above.md} {
      padding: 4rem ${theme.general.pagePadding.md};
    }
    .text {
      > h2,
      > h3,
      > h4,
      > h5,
      > p {
        margin: 0 0 0.5em;
      }
    }
    > * {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
    .image-wrapper {
      width: 150px;
      margin-bottom: 1rem;
    }
    .newsletter-wrapper {
      input {
        background: ${theme.colors.white};
        border: 1px solid #ddd;
        border-radius: 10px;
      }
      button {
        background: ${theme.colors.white};
        color: ${theme.colors.primaryDark};
        font-weight: ${theme.weights.bold};
        border: 1px solid #ddd;
        text-transform: uppercase;
        opacity: 1 !important;
        margin-left: 10px;
        border-radius: 10px;
      }
    }
  }

  &.standard {
    background: ${theme.colors.lightgrey};
    border-top: 1px solid white;
    .text-wrapper {
      max-width: 800px;
      margin: 0 auto;
      .form-wrapper {
        margin-top: 1rem;
        > p {
          text-align: center;
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  const { showNewsletter, newsletter, newsletterImage } =
    useContext(SiteContentContext);

  if (!showNewsletter) return null;

  return (
    <NewsletterBoxWrapper className={'standard'}>
      <MaxWidth>
        <Inner>
          <div className="text-wrapper">
            {newsletterImage && (
              <div className="image-wrapper">
                <Image
                  aspect={'3:1'}
                  sizes={[200]}
                  src={newsletterImage}
                  alt={newsletterImage}
                  crop={false}
                />
              </div>
            )}
            <div className="text">{newsletter}</div>
            <NewsletterField />
          </div>
        </Inner>
      </MaxWidth>
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;
